var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v(" Members ")]),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"columnFilter":{ external: true, lazy: true },"columnFilterValue":_vm.currentFilters,"sorter":{ external: true },"sorterValue":_vm.currentSort,"loading":_vm.isLoading,"responsive":true},on:{"update:columnFilterValue":function($event){_vm.currentFilters=$event},"update:column-filter-value":[function($event){_vm.currentFilters=$event},_vm.changeFilter],"update:sorter-value":_vm.changeSort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status == 0)?_c('CBadge',{attrs:{"color":"warning"}},[_vm._v("Pending Verification")]):_vm._e(),(item.status == 1)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v("Active")]):_vm._e(),(item.status == 2)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v("Suspended")]):_vm._e()],1)]}},{key:"status-filter",fn:function(ref){
var item = ref.item;
return [_c('CSelect',{staticClass:"table-select",attrs:{"size":"sm","options":[ { value: null, label: 'All' }, { value: 0, label: 'Pending Verification' }, { value: 1, label: 'Active' }, { value: 2, label: 'Suspended' } ]},on:{"change":function($event){return _vm.changeStatusFilter($event)}},model:{value:(_vm.currentFilters.status),callback:function ($$v) {_vm.$set(_vm.currentFilters, "status", $$v)},expression:"currentFilters.status"}})]}},{key:"currentBalance-filter",fn:function(ref){
var item = ref.item;
return [_c('div')]}},{key:"currentBalance",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.currentBalance/100))),_c('br'),_c('br'),_c('CButton',{attrs:{"square":"","color":"success","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.opModalShow('deposit', item)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}})],1),_c('CButton',{attrs:{"square":"","color":"warning","size":"sm","variant":"outline"},on:{"click":function($event){return _vm.opModalShow('withdraw', item)}}},[_c('CIcon',{attrs:{"name":"cil-minus"}})],1)],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.email)),_c('br'),_vm._v(" "+_vm._s(item.fullname)+" "+_vm._s(item.mobile)+" ")])]}},{key:"createdAt-filter",fn:function(ref){
var item = ref.item;
return [_c('div')]}},{key:"lastLoggedInAt-filter",fn:function(ref){
var item = ref.item;
return [_c('div')]}},{key:"operations",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButtonGroup')],1)]}},{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]}},[_c('CSpinner',{attrs:{"color":"secondary"}})],1)]},proxy:true},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_vm._v(" No data ")])]},proxy:true}])}),_c('CPagination',{attrs:{"align":"center","activePage":_vm.currentPage,"pages":_vm.pages},on:{"update:activePage":[function($event){_vm.currentPage=$event},_vm.changePage],"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1),_c('CModal',{attrs:{"title":_vm.opModalTitle,"color":_vm.opModalColor,"centered":true,"show":_vm.opModal},on:{"update:show":[function($event){_vm.opModal=$event},_vm.opModalConfirm]}},[_c('CInput',{attrs:{"required":"","placeholder":"Amount"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-dollar"}})]},proxy:true}]),model:{value:(_vm.amountInput),callback:function ($$v) {_vm.amountInput=$$v},expression:"amountInput"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }