import request from '@/api/axios-request'

const apiMember = {

  depositCR(id, amount) {
    return request({
      url: '/ad/members/depositCR',
      method: 'post',
      data: {
        id,
        amount
      }
    })
  },

  withdrawCR(id, amount) {
    return request({
      url: '/ad/members/withdrawCR',
      method: 'post',
      data: {
        id,
        amount
      }
    })
  },

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/members/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  }

}

export default apiMember