<template>
  <div>
    <CCard>
      <CCardHeader>
        Members
      </CCardHeader>
      <CCardBody>
        <CDataTable hover :items='items' :fields='fields' :columnFilter='{ external: true, lazy: true }' :columnFilterValue.sync='currentFilters' :sorter='{ external: true }' :sorterValue='currentSort' @update:sorter-value='changeSort' @update:column-filter-value='changeFilter' :loading='isLoading' :responsive='true'>
          
          <template #status='{item}'>
            <td>
              <CBadge v-if='item.status == 0' color='warning'>Pending Verification</CBadge>
              <CBadge v-if='item.status == 1' color='success'>Active</CBadge>
              <CBadge v-if='item.status == 2' color='danger'>Suspended</CBadge>
            </td>
          </template>

          <template #status-filter='{item}'>
            <CSelect class='table-select' size='sm' v-model='currentFilters.status' :options="[ { value: null, label: 'All' }, { value: 0, label: 'Pending Verification' }, { value: 1, label: 'Active' }, { value: 2, label: 'Suspended' } ]" @change='changeStatusFilter($event)'/>  
          </template>
          
          <template #currentBalance-filter='{item}'>
            <div></div>
          </template>

          <template #currentBalance="{item}">
            <td>{{ item.currentBalance/100 | currency }}<br/><br/>
              <CButton square color='success' size='sm' variant='outline' @click="opModalShow('deposit', item)">
                  <CIcon name='cil-plus'/>
                </CButton>

                <CButton square color='warning' size='sm' variant='outline' @click="opModalShow('withdraw', item)">
                  <CIcon name='cil-minus'/>
                </CButton>
            </td>
          </template>

          <template #email="{item}">
            <td>{{ item.email }}<br/>
              {{ item.fullname }} {{ item.mobile }}
            </td>
          </template>

          <template #createdAt-filter='{item}'>
            <div></div>
          </template>
          
          <template #lastLoggedInAt-filter='{item}'>
            <div></div>
          </template>

          <template #operations='{item, index}'>
            <td class='py-2'>
              <CButtonGroup>


                <!--<CButton square color='success' size='sm' variant='outline' @click="opModalShow('deposit', item)">
                  <CIcon name='cil-plus'/>
                </CButton>

                <CButton square color='warning' size='sm' variant='outline' @click="opModalShow('withdraw', item)">
                  <CIcon name='cil-minus'/>
                </CButton>

                <CButton square color='success' size='sm' variant='outline' v-if='item.status == 1' @click="opModalShow('unsuspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>
                <CButton square color='warning' size='sm' variant='outline' v-if='item.status == 0' @click="opModalShow('suspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>-->

              </CButtonGroup>
            </td>
          </template>

          <template v-slot:loading>
            <CElementCover :boundaries="[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]">
              <CSpinner color='secondary'/>
            </CElementCover>
          </template>

          <template v-slot:no-items-view>
            <div class='text-center my-5'>
              No data
            </div>
          </template>

        </CDataTable>
        <CPagination align='center' :activePage.sync='currentPage' :pages='pages' @update:activePage='changePage'/>
      </CCardBody>

    </CCard>

    <CModal :title='opModalTitle' :color='opModalColor' :centered='true' :show.sync='opModal' @update:show='opModalConfirm'>
      <CInput required placeholder='Amount' v-model='amountInput'>
        <template #prepend-content><CIcon name='cil-dollar'/></template>
      </CInput>
    </CModal>

  </div>
</template>

<script>
import apiMember from '@/api/member'

const items = [
]

const fields = [
  { key: 'username', _style:'min-width:50px' },
  { key: 'email', _style:'min-width:80px' },
  { key: 'status', _style:'min-width:50px;' },
  { key: 'currentBalance', label:'Balance', _style:'min-width:120px' },
  { key: 'createdAt', label: 'Created', _style:'min-width:50px;' },
  { key: 'lastLoggedInAt', label: 'Last Login', _style:'min-width:50px;' },
  { key: 'operations', label: '', _style: 'width:1%', sorter: false, filter: false }
]

export default {
  name: 'ListMember',
  components: {
  },
  data () {
    return {
      isLoading: false,
      items,
      fields,
      pages: 0,
      currentPage: 1,
      currentSortColumn: null,
      currentSortOrder: null,
      currentFilters: {},
      currentSort: {},
      opModal: false,
      opModalOperation: '',
      opModalTitle: '',
      opModalText: '',
      opModalColor: '',
      opModalItem: null,
      amountInput: null
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length == 0){
          this.currentPage = 1
          this.currentSortColumn = null
          this.currentSortOrder = null
          this.currentFilters = {}
        }else{
          if(newVal.page) { this.currentPage = parseInt(newVal.page) }
          if(newVal.sortColumn) { this.currentSortColumn = newVal.sortColumn }
          if(newVal.sortOrder) { this.currentSortOrder = parseInt(newVal.sortOrder) }
          if(newVal.filters) { this.currentFilters = JSON.parse(decodeURI(newVal.filters)) }
          if(newVal.sortColumn && newVal.sortOrder){
            this.currentSort = {column: newVal.sortColumn, asc: (newVal.sortOrder == 1) ? true : false }
          }
        }
        this.fetchList(this.currentPage, 10, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      }
    }
  },
  created() {
  },
  methods: {
    
    /* Table List Functions */
    
    fetchList(page, limit, sortColumn, sortOrder, filters) {
      this.isLoading = true
      apiMember.list(page, limit, sortColumn, sortOrder, filters).then(response => {
        this.items = response.data.data
        this.pages = Math.ceil(response.data.count / 10)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.error('Error loading list.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
      })
    },
    changePage(val) {
      this.currentPage = val
      this.$router.push({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeSort(val) {
      this.currentSortColumn = val.column
      this.currentSortOrder = (val.asc) ? 1 : -1;
      this.$router.push({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeStatusFilter(event) {
      this.currentFilters['status'] = parseInt(event.target.value)
      if(!event.target.value){
       delete this.currentFilters['status'] 
      }
      this.$router.push({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeFilter(val) {
      
      if(val.username == ''){
        delete this.currentFilters['username'] 
      }else{
        this.currentFilters['username'] = val.username
      }
      
      if(val.email == ''){
        delete this.currentFilters['email'] 
      }else{
        this.currentFilters['email'] = val.email
      }

      this.$router.push({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    

    /* List Item Operations */

    opModalShow(operation, item) {
      this.opModal = true
      this.opModalOperation = operation
      this.opModalItem = item
      if(operation == 'deposit' || operation == 'withdraw'){
        if(operation == 'deposit'){
          this.opModalTitle = 'Deposit Credits for "' + item.username.toUpperCase() + '"'
          this.opModalColor = 'success'
        }else{
          this.opModalTitle = 'Withdraw Credits for "' + item.username.toUpperCase() + '"'
          this.opModalColor = 'warning'
        }
      }
    },
    opModalConfirm(show, event, confirm) {
      if(confirm){
        this.opModal = false
        this.isLoading = true
        var convertedAmount = parseInt(this.amountInput * 100)
        if(this.opModalOperation == 'deposit'){
          apiMember.depositCR(this.opModalItem._id, convertedAmount).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), status: require('crypto').randomBytes(24).toString('hex') } })
            this.$toast.success('Successfully deposited.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            this.$toast.error('Error depositing.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
        if(this.opModalOperation == 'withdraw'){
          apiMember.withdrawCR(this.opModalItem._id, convertedAmount).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Member', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), status: require('crypto').randomBytes(24).toString('hex') } })
            this.$toast.success('Successfully withdrawn.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            this.$toast.error('Error depositing.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
        
      }else{
        this.clearOpModalProps()
      }
    },
    clearOpModalProps(){
      this.opModal = false
      this.opModalOperation = ''
      this.opModalTitle = ''
      this.opModalText = ''
      this.opModalColor = ''
      this.opModalItem = null
      this.isLoading = false
      this.amountInput = null
    }
  }
}
</script>
